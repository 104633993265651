const rIframe = {};
rIframe.requestAnimFrame = (function () {

    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();
rIframe.windowHeight = 0;
rIframe.htmlElement = document.getElementsByTagName('html')[0];
rIframe.targetDomain = '*';
rIframe.resizeFrame = function () {
    let body = document.body,
        html = document.documentElement,
        windowHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    if (rIframe.windowHeight === windowHeight) {
        rIframe.requestAnimFrame.call(window, rIframe.resizeFrame);
        return false;
    }
    rIframe.windowHeight = windowHeight;
    try {
        // Same Origin iFrame
        let result = window.frameElement.style.height = windowHeight + 'px';
        console.log(result);
    } catch (e) {
        // Cross Origin iFrame
        window.parent.postMessage('resize:' + windowHeight, '*');
    }
    rIframe.requestAnimFrame.call(window, rIframe.resizeFrame);
};
rIframe.requestAnimFrame.call(window, rIframe.resizeFrame);
